.fa.fa-logo-black-icon.cybergatelearning:before {
  content: "";
  display: block;
  background: url("/logo_web_cybergate.png?v=1");
  width: 150px;
  height: 100px;
  /* background-position: center; */
  background-size: contain;
  background-repeat: no-repeat;
  color: white;
}
.fa.fa-logo-white-icon.cybergatelearning:before {
  content: "";
  display: block;
  background: url("/logo_web_white_cybergate.png?v=1");
  width: 150px;
  height: 100px;
  /* background-position: center; */
  background-size: contain;
  background-repeat: no-repeat;
  color: white;
}

.fa.fa-logo-black-icon.dhlearning:before {
  content: "";
  display: block;
  background: url("/DH_Logo-Black.svg?v=1");
  width: 150px;
  height: 100px;
  /* background-position: center; */
  background-size: contain;
  background-repeat: no-repeat;
  color: white;
}
.fa.fa-logo-white-icon.dhlearning:before {
  content: "";
  display: block;
  background: url("/DH_Logo-White.svg?v=1");
  width: 150px;
  height: 100px;
  /* background-position: center; */
  background-size: contain;
  background-repeat: no-repeat;
  color: white;
}


.fa.fa-logo-black-icon:before {
    content: "";
    display: block;
    background: url("/DH_Logo-Black.svg?v=1");
    width: 204px;
    height: 56px;
    background-position: center;
    background-size: cover;
    color: white;
  }
.fa.fa-logo-white-icon:before {
  content: "";
  display: block;
  background: url("/DH_Logo-White.svg?v=1");
  width: 200px;
  height: 56px;
  background-position: center;
  background-size: cover;
  color: white;
}
 
.fa.fa-logo-aware-icon:before {
  content: "";
  display: block;
  background: url("/dh_navbar_logo.png?v=1");
  width: 38px;
  height: 44px;
  background-position: center;
  background-size: cover;
  color: white;
}